import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '@env/environment';
import {ReservationInterface} from '../util/interfaces.interface';

@Injectable({
  providedIn: 'root'
})
export class ReservationsService {

  constructor(private httpClient: HttpClient) { }

  getReservations(idLocation?: string): Promise<any> {
    const currentMillis = new Date().getTime();
    const hrsAgo = Math.trunc(currentMillis / 1000) - 48 * 60 * 60;

    const path = environment.BACKEND_API_MS_ENDPOINT_URL
      + 'locations/'
      + idLocation
      + '/reservations'
      + '?only_active=false'
      + '&page_size=15'
      + '&sort=-updated_at'
      + '&from='
      + hrsAgo;

    return this.httpClient.get(path).toPromise();
  }

  getCarrierReservations(): Promise<any> {
    const path = environment.BACKEND_API_MS_ENDPOINT_URL + 'shipping/reservations'
      + '?page_size=15'
      + '&sort=-created_at';

    return this.httpClient.get(path).toPromise();
  }

  getPreReservations(page: number, pageSize: number): Promise<any> {
    const path = environment.BACKEND_API_MS_ENDPOINT_URL
      + 'company/pre_reservations'
      + '?page=' + page
      + '&page_size=' + pageSize
      + '&sort=-created_at';

    return this.httpClient.get(path).toPromise();
  }

  getReservation(id): Promise<any> {
    const path = environment.BACKEND_API_MS_ENDPOINT_URL +
      'reservations/' + id;
    return this.httpClient.get(path).toPromise();
  }

  getLocationReservations(idLocation: string, onlyActive: string): Promise<any> {
    const path = environment.BACKEND_API_MS_ENDPOINT_URL
         + 'locations/'
         + idLocation
         + '/reservations'
         + `?only_active=${onlyActive}`
         + '&sort=-updated_at'
         + '&page_size=15';

    return this.httpClient.get(path).toPromise();
  }

  createReservation(body) {
    const path = environment.BACKEND_API_MS_ENDPOINT_URL + 'locations/' + body.id + '/reservations';
    const requestBody = {
      shipment_id: body.shipmentID,
      size: body.size,
    };

    if (body.deliveryConfirmationEnabled) {
      requestBody['delivery_confirmation_enabled'] = body.deliveryConfirmationEnabled;
    }

    if (body.deliveryToken) {
      requestBody['delivery_token'] = body.deliveryToken;
    }

    return this.httpClient.post(path, requestBody).toPromise();
  }

  searchReservation(id: string): Promise<any> {
    const idEncoded = encodeURIComponent(id);
    const path = environment.BACKEND_API_MS_ENDPOINT_URL + 'company/reservations?only_active=false&shipment_id=' + idEncoded;
    return this.httpClient.get(path).toPromise()
      .then((result: {total: number, reservations: Array<ReservationInterface>}) => {
        if (result.total !== 1) {
          throw new Error('More than one reservations found');
        }

        return { reservation: result.reservations.pop() };
      });
  }

  cancelReservation(id: string): Promise<any> {
    const path = environment.BACKEND_API_MS_ENDPOINT_URL + 'reservations/' + id;
    return this.httpClient.delete(path).toPromise();
  }

  returnTokenKey(tokenKey: string) {
    return tokenKey.split('data:image/png;base64,')[1];
  }
}
