import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {
  BoxInterface,
  LocationInterface,
  ReservationInterface,
} from '../../../util/interfaces.interface';
import { GlobalUtil } from '../../../util/global.util';
import { ReservationsService } from '../../../core/reservations.service';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
})
export class SearchResultComponent implements OnInit {
  reservation: ReservationInterface;
  location = '';
  lockerSize = '';
  token = '';
  isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: string },
    private dialogRef: MatDialogRef<SearchResultComponent>,
    public globalUtil: GlobalUtil,
    private reservationService: ReservationsService
  ) {}

  ngOnInit() {
    this.getReservation();
  }

  getReservation(): void {
    this.isLoading = true;
    this.reservationService
      .getReservation(this.data.id)
      .then((result: { reservation: ReservationInterface; tokens: any }) => {
        const address = result.reservation.location.address;
        this.reservation = result.reservation;
        this.token = result.tokens;
        this.location =
          address.street +
          ' ' +
          address.number +
          ', ' +
          address.town +
          ', ' +
          address.city +
          ' ' +
          address.state +
          ', CP. ' +
          address.zip_code;
        this.setLockerSize();
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  setLockerSize(): void {
    const box: BoxInterface = {
      depth: 56,
      height: 9,
      width: 38,
      size: 'CHICO',
    };
    if (this.reservation.locker.size === 'm') {
      box.height = 21;
      box.size = 'MEDIANO';
    } else if (this.reservation.locker.size === 'l') {
      box.height = 34;
      box.size = 'GRANDE';
    }
    this.lockerSize =
      box.size +
      ' (' +
      box.height +
      'cm x ' +
      box.depth +
      'cm x ' +
      box.width +
      'cm)';
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
