import { PackageInformationFormComponent } from './../package-information-form/package-information-form.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import {ReservationsService} from '../../../core/reservations.service';

@Component({
  selector: 'app-tracking-guide',
  templateUrl: './tracking-guide.component.html',
  styleUrls: ['./tracking-guide.component.scss']
})
export class TrackingGuideComponent implements OnInit {
  isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      reservation: any;
      reservationId: string;
    },
    private reservationsService: ReservationsService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<TrackingGuideComponent>,
  ) { }

  ngOnInit() {
    this.getReservation();
  }

  getReservation() {
    if (this.data.reservationId) {
      this.isLoading = true;
      this.reservationsService.getReservation(this.data.reservationId)
        .then((result) => {
          this.data = result;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  public newReservation() {
    this.dialogRef.close();
    this.dialog.open(
      PackageInformationFormComponent,
      {
        width: '80%',
        height: '90%',
        maxHeight: 800,
        maxWidth: 1020,
      },
    );
  }

  public close() {
    this.dialogRef.close();
  }
}
