import { CarriersService } from './carriers.service';
import { MainDashboardComponent } from '../navigation/main-dashboard/main-dashboard.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AsideComponent } from '../navigation/aside/aside.component';
import { RouterModule } from '@angular/router';
import {InfoCardComponent} from '../modules/profile/components/info-card/info-card.component';
import {LocationsService} from './locations.service';
import {ReservationsService} from './reservations.service';
import {DialogResponseComponent} from '../shared/dialog-response/dialog-response.component';
import {EmailService} from './email.service';
import {UsersService} from './users.service';

@NgModule({
  declarations: [AsideComponent, MainDashboardComponent, InfoCardComponent],
  imports: [
    RouterModule,
    CommonModule
  ],
  providers: [
    LocationsService,
    ReservationsService,
    EmailService,
    UsersService,
    CarriersService,
  ],
  exports: [MainDashboardComponent],
  entryComponents: [DialogResponseComponent]
})
export class CoreModule { }
