export interface AsideElement {
  title: string;
  icon?: string;
  route: string;
  permissions?: string[];
  sections?: AsideElement[];
}

export interface ActivityInterface {
  title: string;
  image: string;
  status: string;
  datetime: string;
  lockerID: string;
  lockerSerialNumber: string;
}


export interface UserInterface {
  id?: string;
  name?: string;
  phone?: string;
  company?: CompanyInterface;
  email?: string;
  created_at?: string;
  updated_at?: string;
}

export interface CompanyInterface {
  id: string;
  name: string;
}

export interface LocationInterface {
  public_boxes?: number;
  address?: LocationAddressInterface;
  id?: string;
  boxes?: Array<BoxesInterface>;
  created_at?: string;
  coords?: GPSInterface;
  internal_name?: string;
  phone?: string;
  service_days?: Array<ServiceDayInterface>;
  timezone?: string;
  updated_at?: string;
  uuid?: string;
  lockers_total_count: number;
  lockers_occupied_count: number;
}

export interface LocationAddressInterface {
  city?: string;
  country?: string;
  number?: string;
  state?: string;
  street?: string;
  town?: string;
  zip_code?: string;
}

export interface GPSInterface {
  longitude: number;
  latitude: number;
}

export interface ServiceDayInterface {
  closing_time: string;
  opening_time: string;
  string_weekday: string;
  weekday: number;
}

export interface ManagementInterface {
  operator: string;
  administrator: string;
}

export interface BoxesInterface {
  occupied: number;
  size: string;
  total: number;
}

export interface ReservationInterface {
  id?: string;
  shipment_id: string;
  locker: LockerInterface;
  location: LocationInterface;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
  delivery_due_date?: string;
  event_history: Array<EventHistoryInterface>;
  event?: string;
  codes?: any[];
  deliver_time_limit: number;
  is_confirmed?: boolean;
  pickup_due_date?: string;
  pickup_time_limit: number;
  shipping?: ShippingInterface;
  tokens: TokensInterface;
}
export interface TokensInterface {
  delivery_token?: string;
  delivery_token_qr?: string;
  reopen_1_token?: string;
  reopen_1_token_qr?: string;
  reopen_2_token?: string;
  reopen_2_token_qr?: string;
  pickup_token?: string;
  pickup_token_qr?: string;
  cancel_token?: string;
  cancel_token_qr?: string;
}
export interface ShippingInterface {
  tracking_id: string;
  carrier: string;
  origin: string;
  vehicle_type: string;
  destination: string;
  fee: string;
  required_pickup_date: string;
  estimated_delivery_date: string;
}

export interface EventHistoryInterface {
  status: string;
  date: string;
}

export interface LockerInterface {
  size: string;
  uuid?: string;
}

export interface BoxInterface {
  size?: string;
  depth: number;
  value?: string;
  width: number;
  height: number;
  image?: string;
}

export const Activities = {
  'Deposito': 'assets/images/ActDepositar.svg',
  'Recolección': 'assets/images/ActRecolectar.svg',
  'Depositado': 'assets/images/ActNoDepositar.svg',
  'Cancelación': 'assets/images/ActCancelacion.svg',
  'Reservación': 'assets/images/ActReervacion.svg'
};
