import { FormControl, AbstractControl, ValidatorFn } from '@angular/forms';

export function forbiddenNumberValidator(maxValue: number): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    // tslint:disable-next-line: radix
    const toNumber = parseFloat(control.value) || 0;
    const forbidden = toNumber > maxValue;
    return forbidden ? { numberExceeded: true } : null;
  };
}
