import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {
  constructor(private httpClient: HttpClient) { }
  getLocation(id?: string): Promise<any> {
    const path = environment.BACKEND_API_MS_ENDPOINT_URL + 'company/locations'
      + (id ? `/${id}` : '')
      + (id ? '' : '?page_size=100');
    return this.httpClient.get(path).toPromise();
  }
}
