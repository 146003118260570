import {environment} from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CarriersService {

  public reservation = new BehaviorSubject<any> ({});
  public currentNetworkStatus = this.reservation.asObservable();

  constructor(
    private httpClient: HttpClient) {
  }

  public setReservation(reservation: any): void {
    this.reservation.next(reservation);
  }

  public postAdressToSave(body): Promise<any> {
    const path = environment.BACKEND_API_MS_ENDPOINT_URL + 'company/address';
    return this.httpClient.post(path, body).toPromise();
  }

  public getLocationsForPreReservation(body): Promise<any> {
    const path = environment.BACKEND_API_MS_ENDPOINT_URL + 'locations/available';
    return this.httpClient.post(path, body).toPromise();
  }

  public postPreReservation(locationID: string, body, token: string): Promise<any> {
    const path = environment.BACKEND_API_MS_ENDPOINT_URL + `locations/${locationID}/pre_reservation`;
    const httpOptions = {
      headers: new HttpHeaders({
        'lok-grant-token': `${token}`,
      })
    };
    return this.httpClient.post(path, JSON.stringify(body), httpOptions).toPromise();
  }

  public postQuotation(reservationID: string, body): Promise<any> {
    const path = environment.BACKEND_API_MS_ENDPOINT_URL + `reservations/${reservationID}/shipping/quotation`;
    return this.httpClient.post(path, body).toPromise();
  }

  public postShipping(reservationID: string, body: any): Promise<any> {
    const path = environment.BACKEND_API_MS_ENDPOINT_URL + `reservations/${reservationID}/shipping`;
    return this.httpClient.post(path, body).toPromise();
  }

  updatePreReservation(reservation, id) {
    const path = environment.BACKEND_API_MS_ENDPOINT_URL + 'reservations/' + id;
    return this.httpClient.put(path, reservation).toPromise();
  }

  getShippingLabel(id) {
    const path = environment.BACKEND_API_MS_ENDPOINT_URL + 'reservations/' + id + '/label';
    return this.httpClient.get(path).toPromise();
  }
}

