import { forbiddenNumberValidator } from 'app/shared/custom-validators/number.validator';
import { CompletePickupInformationComponent } from './../complete-pickup-information/complete-pickup-information.component';
import { DialogResponseComponent } from 'app/shared/dialog-response/dialog-response.component';
import { HttpErrorResponse } from '@angular/common/http';
import { CarriersService } from './../../../core/carriers.service';
import { ReservationCheckoutComponent } from './../reservation-checkout/reservation-checkout.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'app/core/auth.service';


const boxes = [
  {
    measuresImage: 'box-s.svg',
    thumbnails: 'suggestions-s.png',
    suggestions: [
      'Celulares, tablets y electrónicos pequeños',
      'Joyería y relojes',
      'Videojuegos y discos',
      'Artículos de cuidado personal',
    ],
    label: 'chico',
    value: 's',
  },
  {
    measuresImage: 'box-m.svg',
    thumbnails: 'suggestions-m.png',
    suggestions: [
      'Consolas de videojuegos',
      'Laptops',
      'Cámaras fotograficas',
      'Electrodomésticos medianos',
    ],
    label: 'mediano',
    value: 'm',
  },
  {
    measuresImage: 'box-l.svg',
    thumbnails: 'suggestions-l.png',
    suggestions: [
      'Pasteles',
      'Impresoras',
      'Arreeglos florales',
      'Cartones de cerveza',
    ],
    label: 'grande',
    value: 'l',
  },
];

@Component({
  selector: 'app-package-information-form',
  templateUrl: './package-information-form.component.html',
  styleUrls: ['./package-information-form.component.scss'],
})
export class PackageInformationFormComponent implements OnInit {
  public packageInformationForm: FormGroup;

  public boxList = boxes;
  public showLoader = false;
  public formatedAddress: string = null;
  public hideDestinationField = false;
  public isPostingLocations = false;
  public hideFormGroup = false;
  private defaultDistanceRatio = 50000;
  public locationId: string;
  public destinationLocationsList = [];
  private grantToken = '';
  public addressNotFound = false;
  public emptyLocations = false;
  public tomorrow = new Date();
  public isEdition = false;
  public isAddressConfirmed = false;
  public reservationId = false;
  public shipmentIdEnabled = true;
  public deliveryConfirmationEnabled = false;
  public auth: AuthService;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      reservation: any;
    },
    private dialog: MatDialog,
    private carriers: CarriersService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<PackageInformationFormComponent>
  ) {
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);
  }

  public confirmAddress() {
    this.isAddressConfirmed = true;
  }

  ngOnInit() {
    this.createPackageInformationForm();
    if (this.data) {
      this.fillDialogData();
    }
  }

  public fillDialogData(): void {
    // if dialog has parameters from prereservation table
    this.isEdition = true;
    this.reservationId = this.data.reservation.id;
    this.packageInformationForm.patchValue({
      aproximatedValue: this.data.reservation.package_info.value || '',
      description: this.data.reservation.package_info.description || '',
      weight: this.data.reservation.package_info.weight || '',
      size: this.data.reservation.locker.size || '',
      shipmentId: this.data.reservation.shipment_id || '',
      date: this.data.reservation.delivery_due_date || this.tomorrow
    });
    this.hideDestinationField = true;
    this.packageInformationForm.controls.destination.setValidators([]);
    this.packageInformationForm.controls.destination.disable();
    this.shipmentIdEnabled = !this.data.reservation.shipment_id;
    // this.confirmationCodeEnabled = !this.data.reservation.confirmation_code;
  }

  public denyAddress() {
    this.formatedAddress = null;
    this.hideFormGroup = false;
    this.ag.controls.reset();
  }

  public createPackageInformationForm(): void {
    this.packageInformationForm = this.fb.group({
      addressSearch: this.fb.group({
        street: ['', [Validators.required, Validators.minLength(2)]],
        zipcode: ['', [Validators.required, Validators.minLength(5)]],
        number: ['', Validators.required],
        references: [''],
        crossStreets: [''],
        coords: this.fb.group({
          lat: [''],
          lng: [''],
        }),
        town: ['', Validators.required],
      }),
      destination: ['', Validators.required],
      description: ['', Validators.required],
      weight: ['', [Validators.required, forbiddenNumberValidator(30)]],
      aproximatedValue: ['', Validators.compose([Validators.required, forbiddenNumberValidator(20000)]) ],
      date: [this.tomorrow, Validators.required],
      size: ['', Validators.required],
      shipmentId: ['', Validators.compose([
          Validators.required,
          Validators.maxLength(100),
          Validators.minLength(3),
          Validators.pattern(/^[A-Za-z0-9_@.ñ#$=!%^)(\]:\*;\?\/\,}{'\|<>\[&\+-]*$/)
      ])],
      deliveryToken: ['', Validators.compose([
          Validators.maxLength(36),
          Validators.minLength(5),
          Validators.pattern(/^[A-Za-z0-9_@.ñ#$=!%^)(\]:\*;\?\/\,}{'\|<>\[&\+-]*$/)
      ])],
      deliveryConfirmationEnabled: [''],
    });
  }

  get form() {
    return this.packageInformationForm.controls;
  }

  public resetFormAfterError(): void {
    this.addressNotFound = false;
    this.hideFormGroup = false;
    this.ag.controls.reset();
  }

  public resetEmptyLocations(): void {
    this.emptyLocations = false;
    this.hideFormGroup = false;
  }

  public addPackageInformation(): void {
    if (this.packageInformationForm.invalid) {
      this.packageInformationForm.markAllAsTouched();
      return;
    }
    this.createPreReservation();
  }

  public handleShipmentId() {
    const trimValue = this.form.shipmentId.value.trim().replace(/\s/g, '');
    this.form.shipmentId.setValue(trimValue);
  }

  public handleDeliveryToken() {
    const trimValues = this.form.deliveryToken.value.trim().replace(/\s/g, '');
    this.form.deliveryToken.setValue(trimValues);
  }

  public closeModal(): void {
    this.dialogRef.close(false);
  }

  get addressGroup() {
    return this.packageInformationForm.controls.addressSearch as FormGroup;
  }

  get ag() {
    return this.addressGroup.controls;
  }

  public setAddressCoordinates(coordinates: any) {
    this.addressGroup.controls.coords.get('lng').setValue(coordinates.long);
    this.addressGroup.controls.coords.get('lat').setValue(coordinates.lat);
  }

  public getPreservationBody(coords): any {
    const body = {
      coords: {
        long: coords.long,
        lat: coords.lat,
      },
      max_distance: this.defaultDistanceRatio,
      limit: 100,
    };
    return body;
  }

  public setDestinationLocationsList(locations) {
    this.destinationLocationsList = locations;
  }

  public setGrantTokenForPreservation(grant: string) {
    this.grantToken = grant;
  }

  public createPreReservationBody() {
    const formValue = this.packageInformationForm.value;
    this.locationId = formValue.destination;

    const preReservationBody = {
      size: formValue.size,
      delivery_date: new Date(formValue.date).toISOString(),
      package_info: {
        value: formValue.aproximatedValue,
        description: formValue.description,
        weight: formValue.weight,
      },
    };

    if (formValue.deliveryToken) {
      preReservationBody['delivery_token'] = formValue.deliveryToken;
    }

    if (this.deliveryConfirmationEnabled) {
      preReservationBody['delivery_confirmation_enabled'] =
        this.deliveryConfirmationEnabled;
    }

    if (this.shipmentIdEnabled) {
      preReservationBody['shipment_id'] = formValue.shipmentId;
    }

    return preReservationBody;
  }

  public closeFormAndOpenTicketCheckout(): void {
    this.dialogRef.close();
    this.dialog.open(ReservationCheckoutComponent, {
      width: '80%',
      height: '90%',
      maxHeight: 800,
      maxWidth: 1020,
    });
  }

  public async searchForAnAddress() {
    this.addressGroup.markAllAsTouched();
    if (this.addressGroup.invalid) {
      return;
    }

    this.isPostingLocations = true;
    const searchLocationObject = this.addressGroup.value;
    const body = {
      zip_code: searchLocationObject.zipcode,
      number: searchLocationObject.number,
      references: searchLocationObject.references,
      cross_streets: searchLocationObject.crossStreets,
      town: searchLocationObject.town,
      street: searchLocationObject.street,
    };

    try {
      const {
        coordinates,
        formatted_address,
      } = await this.carriers.postAdressToSave(body);
      this.setAddressCoordinates(coordinates);
      const preReservationBody = this.getPreservationBody(coordinates);
      const {
        locations,
        grant,
      } = await this.carriers.getLocationsForPreReservation(preReservationBody);
      if (locations.length === 0) {
        this.emptyLocations = true;
        this.hideFormGroup = true;
      } else {
        this.formatedAddress = formatted_address;
        this.hideFormGroup = true;
        this.setDestinationLocationsList(locations);
        this.setGrantTokenForPreservation(grant);
      }
    } catch (error) {
      this.addressNotFound = true;
      this.hideFormGroup = true;
    } finally {
      this.isPostingLocations = false;
    }
  }

  /**
   * Creates a prereservation API Call
  */
  public createPreReservation(): void {
    const body = this.createPreReservationBody();
    this.showLoader = true;
    if (this.data && this.data.reservation) {
      this.updatePreReservation(body);
    } else {
      this.postPrereservation(body);
    }
  }

  /**
   * Update a prereservation
  */
  public postPrereservation(body): void {
     this.carriers
      .postPreReservation(this.locationId, body, this.grantToken)
      .then((response) => {
        this.openCompleteComponent(response);
      })
      .catch((error) => {
        this.handlePrereservationErrors(error);
      })
      .finally(() => {
        this.showLoader = false;
      });
 }

  public updatePreReservation(body): void {
    this.carriers.updatePreReservation(body, this.reservationId)
        .then((response) => {
          this.openCompleteComponent(response);
        })
        .catch((error) => {
          this.handlePrereservationErrors(error);
        })
        .finally(() => {
          this.showLoader = false;
        });
  }

   /**
   * Handles  error from prereservation API call
  */
  public handlePrereservationErrors(error: HttpErrorResponse): void {
    const { status_code, type } = error.error;
    const data = {
      title: this.getHumanTitleErrorMessage(status_code),
      message: this.getHumanErrorMessage(status_code, type),
      buttonMessage: 'Intentar de nuevo',
      otherOption: 'Salir',
      image: 'sad.svg',
    };
    const dialogRef = this.dialog.open(DialogResponseComponent, { data });
    dialogRef
      .afterClosed().subscribe((result) => {
        if (!result) {
          this.dialog.closeAll();
        }
      });
  }

  /**
   * Returns a human readable result
   * title to bw shown on modal
  */
  public getHumanTitleErrorMessage(status: number): string  {
    let title;
    switch (status) {
      case 404:
        title = 'Recurso no encontrado';
        break;
      case 500:
        title = 'Oops.. Error inesperado';
        break;
      case 422:
        title = 'No pudimos encontrar un box para ti :(';
        break;
      default:
        title = 'Ocurrió un error intentando crear la reservación';
        break;
    }
    return title;
  }

  /**
   * Returns a human readable result
   * message to bw shown on modal
  */
  public getHumanErrorMessage(status: number, type: string): string {
    let message;
    switch (status) {
      case 404:
        message = `No pudimos dar con el servicio que intentas obtener (${type})`;
        break;
      case 500:
        message = `En unos momentos intentaremos reestablecer el servicio (${type})`;
        break;
      case 422:
        message =
          `No pudimos encontrar un box que
          pudiera soportar las dimensiones
          del paquete que intentas guardar,
          te sugerimos corroborar los datos de tu reservación (${type})`;
        break;
      default:
        message = `Intenta crear una reservación de nuevo  (${type})`;
        break;
    }
    return message;
  }

  /**
   * Creates reservation quotation
   * Gets a price and  generates details to be shown in a checkout
   * modal component
   */

  public openCompleteComponent(response: any): void {
    const { reservation } = response;
    const { id } =  reservation;
    const address = this.addressGroup.value;
    const data = {
      reservation_id: id,
      origin: {
        street: address.street,
        number: address.number,
        town: address.town,
        zip_code: address.zipcode,
        references: address.references,
        cross_streets: address.crossStreets,
      },
    };


    if (this.data && this.data.reservation) {
      data['isEdition'] = true;
    }

    this.dialog.closeAll();
    this.dialog.open(CompletePickupInformationComponent, {
      data: data,
      width: '90%',
      height: '95%',
      maxHeight: 780,
      maxWidth: 1020,
      disableClose: true,
    });
  }

  onCheckboxChange(e) {
    this.deliveryConfirmationEnabled = e.target.checked;
  }

  }
