import { BehaviorSubject } from 'rxjs';
import { environment } from './../../environments/environment';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import Nes from '@hapi/nes/lib/client';

@Injectable()

export class AuthSocket {

  constructor(private authService: AuthService) {
      this.client = new Nes.Client(environment.SOCKETIOCONFIG.url);
      this.client.onConnect = () => {
        this.connected.next(true);
      };
      this.client.onDisconnect = (willReconnect) => {
        this.connected.next(false);
        this.isReconnecting.next(willReconnect);
      };
    }

  private static instance: AuthSocket = null;
  connected = new BehaviorSubject<Boolean>(false);
  currentConnection = this.connected.asObservable();
  isReconnecting = new BehaviorSubject<Boolean>(false);
  isCurrentlyReconnecting = this.isReconnecting.asObservable();
  client: Nes.Client;

  public static getInstance(auth: AuthService) {
    if (AuthSocket.instance === null) {
      AuthSocket.instance = new AuthSocket(auth);
    }

    return AuthSocket.instance;
  }

    connect() {
      return this.client.connect({
        auth: {
          headers: {
            Authorization: `Bearer ${this.authService.accessTokenInfo}`
          }
        },
        retries: 10
      });
    }

    disconnect() {
      return this.client.disconnect();
    }

    authenticate(token: string) {
      if (this.connected.getValue()) {
        return this.client.reauthenticate({ headers: { Authorization: `Bearer ${token}` } });
      }
    }
}


