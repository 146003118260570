export const environment = {
  production: false,
  name: 'DEV',
  BACKEND_API_MS_ENDPOINT_URL: 'https://lockers-gateway.chazki.com/api/v2/',
  BACKEND_API_CREDENTIALS: {
    clientID: 'T4kit043v3r',
    clientSecret: 'dashboardAdmin',
  },
  SOCKETIOCONFIG: {
    url: 'wss://lockers-gateway.chazki.com/',
    options: {},
  },
};
