import { Component, OnInit } from '@angular/core';
import {Activities, ActivityInterface} from '../../../../util/interfaces.interface';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  activities: Array<ActivityInterface> = [
    {
      title: 'Deposito',
      image: '',
      status: 'Completado',
      datetime: '30 Agosto 2019, 11:08 PM',
      lockerID: 'ID de locker',
      lockerSerialNumber: 'serie'
    },
    {
      title: 'Recolección',
      image: '',
      status: 'Completado',
      datetime: '30 Agosto 2019, 11:08 PM',
      lockerID: 'ID de locker',
      lockerSerialNumber: 'serie'
    },
    {
      title: 'Depositado',
      image: '',
      status: 'Pendiente',
      datetime: '26 Agosto 2019',
      lockerID: 'ID de locker',
      lockerSerialNumber: 'serie'
    },
    {
      title: 'Cancelación',
      image: '',
      status: 'Completado',
      datetime: '30 Agosto 2019, 11:08 PM',
      lockerID: 'ID de locker',
      lockerSerialNumber: 'serie'
    },
    {
      title: 'Reservación',
      image: '',
      status: 'Completado',
      datetime: '30 Agosto 2019, 11:08 PM',
      lockerID: 'ID de locker',
      lockerSerialNumber: 'serie'
    },
    {
      title: 'Deposito',
      image: '',
      status: 'Completado',
      datetime: '30 Agosto 2019, 11:08 PM',
      lockerID: 'ID de locker',
      lockerSerialNumber: 'serie'
    },
    {
      title: 'Deposito',
      image: '',
      status: 'Completado',
      datetime: '30 Agosto 2019, 11:08 PM',
      lockerID: 'ID de locker',
      lockerSerialNumber: 'serie'
    },
    {
      title: 'Deposito',
      image: '',
      status: 'Completado',
      datetime: '30 Agosto 2019, 11:08 PM',
      lockerID: 'ID de locker',
      lockerSerialNumber: 'serie'
    }
  ];

  constructor() {
    this.activities.forEach(i => {
      i.image = Activities[i.title];
    });
  }

  ngOnInit() {
  }

}
