import { MatSelectModule } from '@angular/material/select';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogResponseComponent } from './dialog-response/dialog-response.component';
import { CreateReservationComponent } from './create-reservation/create-reservation.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MatButtonModule,
  MatDividerModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatStepperModule,
  MatProgressSpinnerModule,
  MatNativeDateModule,
  MAT_DATE_LOCALE,
} from '@angular/material';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { NgxBarcodeModule } from 'ngx-barcode';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectLocationComponent } from './create-reservation/select-location/select-location.component';
import { SelectBoxComponent } from './create-reservation/select-box/select-box.component';
import { SelectReceiverComponent } from './create-reservation/select-receiver/select-receiver.component';
import { CancelReservationComponent } from './cancel-reservation/cancel-reservation.component';
import { CompleteReservationComponent } from './create-reservation/complete-reservation/complete-reservation.component';
import { ConfirmCancelationComponent } from './cancel-reservation/confirm-cancelation/confirm-cancelation.component';
import { SearchReservationComponent } from './search-reservation/search-reservation.component';
import { SearchResultComponent } from './search-reservation/search-result/search-result.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
/* Logistics compoonents*/
import { PackageInformationFormComponent } from './carrier-reservation/package-information-form/package-information-form.component';
import { ReservationCheckoutComponent } from './carrier-reservation/reservation-checkout/reservation-checkout.component';
import { TrackingGuideComponent } from './carrier-reservation/tracking-guide/tracking-guide.component';
import { TrackingGuideTicketComponent } from './carrier-reservation/tracking-guide-ticket/tracking-guide-ticket.component';
// tslint:disable-next-line: max-line-length
import { CompletePickupInformationComponent } from './carrier-reservation/complete-pickup-information/complete-pickup-information.component';

import { NgxMaskModule, IConfig } from 'ngx-mask';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    DialogResponseComponent,
    CreateReservationComponent,
    SelectLocationComponent,
    SelectBoxComponent,
    SelectReceiverComponent,
    CancelReservationComponent,
    CompleteReservationComponent,
    ConfirmCancelationComponent,
    SearchReservationComponent,
    SearchResultComponent,
    PackageInformationFormComponent,
    ReservationCheckoutComponent,
    TrackingGuideComponent,
    TrackingGuideTicketComponent,
    CompletePickupInformationComponent,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressSpinnerModule,
    NgxMaskModule.forRoot(maskConfig),
    MatNativeDateModule,
    MatInputModule,
    MatButtonModule,
    MatDividerModule,
    MatStepperModule,
    MatSelectModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    FormsModule,
    NgxBarcodeModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-MX' }
  ],
  exports: [
    DialogResponseComponent,
    CreateReservationComponent,
  ],
  entryComponents: [
    DialogResponseComponent,
    CreateReservationComponent,
    CancelReservationComponent,
    ConfirmCancelationComponent,
    CompleteReservationComponent,
    SearchReservationComponent,
    SearchResultComponent,
    /* Carriers reservation components */
    PackageInformationFormComponent,
    ReservationCheckoutComponent,
    TrackingGuideComponent,
    CompletePickupInformationComponent,
  ],
})
export class SharedModule {}
