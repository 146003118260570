import { DialogResponseComponent } from './../../dialog-response/dialog-response.component';
import { CarriersService } from './../../../core/carriers.service';
import { PackageInformationFormComponent } from './../package-information-form/package-information-form.component';
import { ReservationCheckoutComponent } from './../reservation-checkout/reservation-checkout.component';
import { MatDialog } from '@angular/material';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-complete-pickup-information',
  templateUrl: './complete-pickup-information.component.html',
  styleUrls: ['./complete-pickup-information.component.scss'],
})
export class CompletePickupInformationComponent implements OnInit {
  public pickupInformationForm: FormGroup;
  public showLoader = false;
  private recipientEmailValidators = [
    Validators.email,
    Validators.maxLength(50),
    Validators.minLength(3),
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      reservation_id: string;
      origin: {
        street: string;
        number: string;
        town: string;
        zip_code: string;
        references: string;
        cross_streets: string;
      },
      isEdition: boolean,
    },
    private dialog: MatDialog,
    public carriers: CarriersService,
    private dialogRef: MatDialogRef<CompletePickupInformationComponent>,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.createPickupInformationForm();
  }

  public createPickupInformationForm(): void {
    this.pickupInformationForm = this.fb.group({
      name: ['', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(14),
        Validators.pattern(/^[\p{L} ]*$/iu)
      ]],
      lastname: ['', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(14),
        Validators.pattern(/^[\p{L} ]*$/iu)
      ]],
      phoneNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      recipientName: ['', [
        Validators.minLength(5),
        Validators.maxLength(14),
        Validators.pattern(/^[\p{L} ]*$/iu)
      ]],
      recipientEmail: ['', this.recipientEmailValidators],
      notificationsEnabled: [false],
    });

    this.pickupInformationForm.get('notificationsEnabled').valueChanges
      .subscribe(value => {
        this.pickupInformationForm.get('recipientEmail')
          .setValidators(this.recipientEmailValidators.concat(value ? Validators.required : Validators.nullValidator));
        this.pickupInformationForm.get('recipientEmail').updateValueAndValidity();
      });
  }

  public goBack(): void {
    this.openPreviousForm();
    this.dialogRef.close();
  }

  public openPreviousForm(): void {
    this.dialog.open(PackageInformationFormComponent, {
      width: '90%',
      height: '95%',
      maxHeight: 800,
      maxWidth: 1020,
    });
  }

  public onFormSubmit() {
    if (this.pickupInformationForm.invalid) {
      this.pickupInformationForm.markAllAsTouched();
      return;
    }

    const body = this.createPickupInformationBody();
    this.carriers.setReservation({
      id: this.data.reservation_id,
      ...body,
    });

    return this.carriers.updatePreReservation(this.createReservationUpdateBody(), this.data.reservation_id)
      .then(() => {
        return this.postQuotation(body);
      });
  }

  onCheckboxChange(e) {
    this.pickupInformationForm.get('notificationsEnabled').setValue(e.target.checked);
  }

  public createPickupInformationBody() {
    const value = this.pickupInformationForm.value;
    const body = {
      origin: this.data.origin,
      contact: {
        phone: value.phoneNumber,
        name: `${value.name} ${value.lastname}`.trim(),
      },
    };
    return body;
  }

  createReservationUpdateBody() {
    const value = this.pickupInformationForm.value;
    const body = {
      recipient_info: {
        name: value.recipientName,
        email: value.recipientEmail,
      },
      recipient_notifications_enabled: value.notificationsEnabled,
    };
    return body;
  }

   /**
   * Create quotation from reservation id
  */
  public postQuotation(body: any): void {
    this.showLoader = true;
    const id = this.data.reservation_id;
    this.carriers
      .postQuotation(id, body)
      .then((response) => {
        const { quoting } = response;
        this.openQuotationSummary(quoting);
      })
      .catch((error) => {
        this.handleQuotationErrors(error);
      })
      .finally(() => {
        this.showLoader = false;
      });
  }

  public openQuotationSummary(params): void {
    this.dialogRef.close();
    this.dialog.open(ReservationCheckoutComponent, {
      width: '80%',
      height: '90%',
      maxHeight: 800,
      maxWidth: 1020,
      disableClose: true,
      data: {
        reservation: params,
        isEdition: this.data.isEdition,
      },
    });
  }

  /**
   * Returns a human readable result
   * title to be shown on modal
   */
  public getHumanTitleErrorMessage(status: number): string {
    let title;
    switch (status) {
      case 400:
        title = 'Ocurrió un error al procesar la solicitud';
        break;
      case 403:
        title = 'Ocurrió un error intentando crear la reservación';
        break;
      case 410:
        title = 'El servicio no se encuentra disponible';
        break;
      default:
        title = 'Ocurrió un error intentando crear la reservación';
        break;
    }
    return title;
  }

  /**
   * Returns a human readable result
   * message to bw shown on modal
   */
  public getHumanErrorMessage(status: number, type: string): string {
    let message;
    switch (status) {
      case 400:
        message = `Ocurrió un error al procesar la solicitud (${type})`;
        break;
      case 403:
        message = `La sesión caducó o los permisos del usuario son insuficientes (${type})`;
        break;
      case 410:
        message = `Intentamos crear tu reservación pero el servicio no se encuentra disponible,
      intenta crear tu reservación más tarde (${type})`;
        break;
      default:
        message = `Intenta crear una reservación de nuevo  (${type})`;
        break;
    }
    return message;
  }

  public handleQuotationErrors(error): void {
    const { status_code, type } = error.error;
    const data = {
      title: this.getHumanTitleErrorMessage(status_code),
      message: this.getHumanErrorMessage(status_code, type),
      buttonMessage: 'Intentar de nuevo',
      otherOption: 'Salir',
      image: 'sad.svg',
    };
    const dialogRef = this.dialog.open(DialogResponseComponent, { data });
    dialogRef
      .afterClosed().subscribe((result) => {
        if (!result) {
          this.dialog.closeAll();
        }
      });
  }

  public closeModal(): void {
    this.dialogRef.close(false);
  }

  get form() {
    return this.pickupInformationForm.controls;
  }
}
