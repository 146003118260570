import {Injectable, NgZone, EventEmitter, Output} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {environment} from '@env/environment';
import {MatDialog} from '@angular/material';
import {DialogResponseComponent} from '../shared/dialog-response/dialog-response.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivateChild {
  @Output() isLoggedIn = new EventEmitter<boolean>(false);
  userScope = '';
  constructor(private httpClient: HttpClient,
              private router: Router,
              private dialog: MatDialog,
              private ngZone: NgZone) {
  }

  get accessTokenInfo(): string {
    return AuthService.tokenInfo.access_token;
  }

  get refreshTokenInfo(): string {
    return AuthService.tokenInfo.refresh_token;
  }

  private static get tokenInfo() {
    const responseToken = JSON.parse(localStorage.getItem('responseToken'));
    if (responseToken === null) {
      throw new Error();
    }
    return responseToken;
  }

  private static getEncodedCredentials(client_id: string, client_secret: string): string {
    const credentials = `${client_id}:${client_secret}`;
    return window.btoa(credentials);
  }

  isAuthenticated() {
    return localStorage.getItem('responseToken') != null;
  }

  login(auth) {
    const path = environment.BACKEND_API_MS_ENDPOINT_URL + 'oauth/authorize';
    const body = new HttpParams()
          .set('grant_type', 'password')
          .set('client_id', environment.BACKEND_API_CREDENTIALS.clientID)
          // .set('client_secret', environment.BACKEND_API_CREDENTIALS.clientSecret)
          .set('username', auth.username)
          .set('password', auth.password);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      })
    };

    return this.httpClient.post(path, body.toString(), httpOptions).toPromise();
  }

  async handleError(error: HttpErrorResponse) {
    switch (error.error.status_code) {
      case 400:
        if (error.url === environment.BACKEND_API_MS_ENDPOINT_URL + 'oauth/authorize') {
          this.dialog.open(DialogResponseComponent, {
            data: {title: 'Error al iniciar sesión', message: 'Usuario y/o contraseña incorrectos'}
          });
        } else {
          const dialog = this.dialog.open(DialogResponseComponent, {
            data: {title: 'Error desconocido', message: 'Por favor contacta a soporte.'}
          });
          // tslint:disable-next-line: deprecation
          dialog.afterClosed().subscribe(() => {
            this.ngZone.run(() => this.router.navigateByUrl('login').then()).then();
          });
        }
        break;
      default:
        break;
    }
  }


  refreshToken() {
    const path = environment.BACKEND_API_MS_ENDPOINT_URL + 'oauth/authorize';
    const body = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('client_id', environment.BACKEND_API_CREDENTIALS.clientID)
      // .set('client_secret', environment.BACKEND_API_CREDENTIALS.clientSecret)
      .set('refresh_token', this.refreshTokenInfo);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      })
    };
    return this.httpClient.post(path, body.toString(), httpOptions);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.isAuthenticated()) {
      this.ngZone.run(() => this.router.navigateByUrl('login').then()).then();
      return false;
    }
    return true;
  }

  logout() {
    const dialogRef = this.dialog.open(DialogResponseComponent, {
      data: {
        title: '¿Seguro que deseas salir?',
        message: 'Los cambios no guardados se perderán',
        buttonMessage: 'Aceptar',
        otherOption: 'Cancelar'
      }
    });
    // tslint:disable-next-line: deprecation
    dialogRef.afterClosed().subscribe(value => {
      if (value) {
        localStorage.clear();
        this.ngZone.run(() => this.router.navigateByUrl('login').then()).then();
      }
    });
  }


  get scope() {
    return JSON.parse(localStorage.getItem('scope'));
  }

}
