import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {LocationInterface} from '../../../util/interfaces.interface';
import {GlobalUtil} from '../../../util/global.util';
import {MatDialogRef} from '@angular/material';
import {LocationsService} from '../../../core/locations.service';

@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.scss']
})
export class SelectLocationComponent implements OnInit {

  selectedCity: string;
  selectedLocation: string;
  cities: Array<string> = [];
  locations: Array<LocationInterface> = [];
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onLocationChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(public globalUtil: GlobalUtil,
              private locationsService: LocationsService,
              private dialogRef: MatDialogRef<SelectLocationComponent>) {
  }

  ngOnInit() {
    this.locationsService.getLocation().then((locations: { locations: Array<LocationInterface> }) => {
      const aux = new Set<string>();
      for (let index = 0; index < locations.locations.length; index++) {
        aux.add(locations.locations[index].address.city);
      }
      this.cities = Array.from(aux);
      this.selectedCity = this.cities[0];
      this.onUpdateData();
    });
  }

  selectLocation(id: string): void {
    this.selectedLocation = id;
    this.onLocationChange.emit(this.selectedLocation);
  }

  onUpdateData(): void {
    this.selectedLocation = undefined;
    this.locationsService.getLocation()
        .then((locations: { locations: Array<LocationInterface> }) => {
          this.locations = locations.locations.filter(location => this.selectedCity === location.address.city);
        });
  }

  onBack(): void {
    this.dialogRef.close(false);
  }

}
