import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ReservationInterface} from '../../../util/interfaces.interface';
import {GlobalUtil} from '../../../util/global.util';
import {ReservationsService} from '../../../core/reservations.service';

@Component({
  selector: 'app-confirm-cancelation',
  templateUrl: './confirm-cancelation.component.html',
  styleUrls: ['./confirm-cancelation.component.scss']
})
export class ConfirmCancelationComponent implements OnInit {

  shipmentID = '';
  reservationState = 'activo';
  creationDate = '2019-12-10T15:05:31.702Z';
  modificationDate = '2020-01-29T22:05:31.702Z';

  constructor(private dialogRef: MatDialogRef<ConfirmCancelationComponent>,
              private globalUtil: GlobalUtil,
              private reservationsService: ReservationsService,
              @Inject(MAT_DIALOG_DATA) private data: { reservation: ReservationInterface }) {
  }

  ngOnInit() {
    this.shipmentID = this.data.reservation.shipment_id;
    this.creationDate = this.data.reservation.created_at;
    this.modificationDate = this.data.reservation.updated_at;
    this.reservationState = this.globalUtil.getStatus(this.globalUtil.getLastEvent(this.data.reservation).status);
  }

  onClose(value?: number): void {
    if (value === 1) {
      this.reservationsService.cancelReservation(this.data.reservation.id)
        .then(() => {
          this.dialogRef.close({ output: 1 });
        }).catch((error) => {
         this.dialogRef.close({ output: -1, error });
         });
    } else {
      this.dialogRef.close(value);
    }
  }
}
