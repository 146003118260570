import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-dialog-response',
  templateUrl: './dialog-response.component.html',
  styleUrls: ['./dialog-response.component.scss']
})
export class DialogResponseComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
                title: string,
                message: string,
                secondaryMessage?: string,
                image?: string,
                buttonMessage: string,
                otherOption?: string
              },
              private dialogRef: MatDialogRef<DialogResponseComponent>) {
  }

  ngOnInit() { }

  onClose(value: boolean, openWindow?): void {
    this.dialogRef.close(value);
  }

}
