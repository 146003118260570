import { DialogResponseComponent } from './../../dialog-response/dialog-response.component';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReservationsService } from '../../../core/reservations.service';
import { EmailService } from '../../../core/email.service';
import { ManagementInterface } from 'app/util/interfaces.interface';
import { AuthService } from 'app/core/auth.service';

@Component({
  selector: 'app-select-receiver',
  templateUrl: './select-receiver.component.html',
  styleUrls: ['./select-receiver.component.scss'],
})
export class SelectReceiverComponent implements OnInit {
  loading = false;
  reservation: any;
  isDeliveryTokenRequired = false;
  managementEmails: ManagementInterface;
  @Input() boxSize: string;
  @Input() location: string;

  public emailForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<SelectReceiverComponent>,
    public auth: AuthService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private reservationsService: ReservationsService,
    private emailService: EmailService,
  ) {}

  ngOnInit(): void {
    this.getEmailOperators();
    this.initializeForm();
  }

  private initializeForm(): void {
    this.emailForm = this.formBuilder.group({
      emailOperator: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.maxLength(50),
          Validators.minLength(3),
        ]),
      ],
      emailAdmin: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.maxLength(50),
          Validators.minLength(3),
        ]),
      ],
      shipmentID: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(100),
          Validators.minLength(3),
          Validators.pattern(
            /^[A-Za-z0-9_@.ñ#$=!%^)(\]:\*;\?\/\,}{'\|<>\[&\+-]*$/,
          ),
        ]),
      ],
      deliveryToken: [
        '',
        Validators.compose([
          // Validators.required,
          Validators.maxLength(36),
          Validators.minLength(5),
          Validators.pattern(
            /^[A-Za-z0-9_@.ñ#$=!%^)(\]:\*;\?\/\,}{'\|<>\[&\+-]*$/,
          ),
        ]),
      ],
    });
  }

  get f() {
    return this.emailForm.controls;
  }

  public clearInput() {
    const trimValue = this.f.shipmentID.value.trim().replace(/\s/g, '');
    this.f.shipmentID.setValue(trimValue);
    const trimValues = this.f.deliveryToken.value.trim().replace(/\s/g, '');
    this.f.deliveryToken.setValue(trimValues);
  }

  async getEmailOperators() {
    this.loading = true;
    this.emailService
      .getEmailOperators()
      .then((result) => {
        this.managementEmails = result.management_emails;
      })
      .finally(() => {
        this.loading = false;
        this.emailForm.controls['emailOperator'].setValue(
          this.managementEmails.operator || '',
        );
        this.emailForm.controls['emailAdmin'].setValue(
          this.managementEmails.administrator || '',
        );
      });
  }



  onContinue() {
    if (String(this.emailForm.value.shipmentID).trim().length === 0) {
      this.dialog.open(DialogResponseComponent, {
        data: {
          title: 'Número de pedido inválido',
          message:
            'El número de pedido está vacío, ingresa un número de pedido válido',
          buttonMessage: 'Entendido',
        },
      });
      return;
    }

    this.loading = true;

    const body = {
      deliveryConfirmationEnabled: Boolean(this.isDeliveryTokenRequired),
      deliveryToken: String(this.emailForm.value.deliveryToken).trim(),
      shipmentID: String(this.emailForm.value.shipmentID).trim(),
      size: this.boxSize,
      id: this.location,
    };

    // console.log(body);

    return this.reservationsService
      .createReservation(body)
      .then((reservation: any) => {
        const data = {};
        const { administrator, operator } = this.managementEmails;
        const { emailOperator, emailAdmin } = this.emailForm.value;
        if (administrator !== emailAdmin) {
          data['admin'] = emailAdmin;
        }
        if (operator !== emailOperator) {
          data['operator'] = emailOperator;
        }
        return this.emailService
          .sendEmails(reservation.reservation.id, data)
          .then(() => {
            this.dialogRef.close({ reservation: reservation });
          });
      })
      .catch((error) => {
        this.dialogRef.close({
          shipment_id: this.emailForm.value.shipmentID,
          delivery_token: this.emailForm.value.deliveryToken,
          error,
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  get isAdminEmailsDisabled() {
    if (this.auth.scope !== 'owner' && this.auth.scope !== 'seller') {
      return true;
    } else {
      return false;
    }
  }

  get isOperatorEmailsDisabled() {
    if (this.auth.scope !== 'owner' && this.auth.scope !== 'seller') {
      return true;
    } else {
      return false;
    }
  }

  get isDeliveryTokenShowed() {
    if (this.auth.scope === 'seller') {
      return true;
    } else {
      return false;
    }
  }

  onCheckboxChange(e) {
    this.isDeliveryTokenRequired = e.target.checked;
  }
}
