import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileRoutingModule } from './profile-routing.module';
import { PersonalCardComponent } from './components/personal-card/personal-card.component';
import { MainComponent } from './pages/main/main.component';
import { ReportsCardComponent } from './components/reports-card/reports-card.component';

@NgModule({
  declarations: [MainComponent, PersonalCardComponent, ReportsCardComponent],
  imports: [
    CommonModule,
    ProfileRoutingModule
  ]
})
export class ProfileModule { }
