import { FormControl, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogResponseComponent } from 'app/shared/dialog-response/dialog-response.component';
import { CarriersService } from './../../../core/carriers.service';
import { PackageInformationFormComponent } from './../package-information-form/package-information-form.component';
import { TrackingGuideComponent } from './../tracking-guide/tracking-guide.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-reservation-checkout',
  templateUrl: './reservation-checkout.component.html',
  styleUrls: ['./reservation-checkout.component.scss']
})
export class ReservationCheckoutComponent implements OnInit {

  public reservationShipping: any;
  public showLoader = false;
  public showDatepicker = false;
  public tomorrow = new Date();
  public reservationId: string;
  public isDateUpdated = false;
  public editedPickupDate = new FormControl(new Date(), Validators.required);
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      reservation: any;
      isEdition: boolean,
    },
    private dialog: MatDialog,
    private carriers: CarriersService,
    private dialogRef: MatDialogRef<ReservationCheckoutComponent>,
  ) {
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);
  }

  ngOnInit() {
    this.carriers.currentNetworkStatus.subscribe((reservation) => {
      this.reservationShipping = reservation;
      this.reservationId = reservation.id;
    });
   }

  public goBack(): void {
    this.dialogRef.close();
    this.openPreviousForm();
  }

  public closeModal(): void {
    this.dialogRef.close(false);
  }

  public openPreviousForm(): void {
    this.dialogRef.close();
    this.dialog.open(
      PackageInformationFormComponent,
      {
        width: '50%',
        height: '50%',
        maxHeight: 800,
        maxWidth: 1020,
      },
    );
  }

  public showPickupDatePicker( ) {
    this.showDatepicker = !this.showDatepicker;
    this.editedPickupDate.setValue(this.data.reservation.required_pickup_date);
  }

  public updateReservationDate(): void {
    this.showLoader = true;
    const body = {
      delivery_date: new Date(this.editedPickupDate.value).toISOString(),
    };
    this.updatePreReservation(body);
  }

  public updatePreReservation(body): void {
    this.carriers.updatePreReservation(body, this.reservationId)
        .then((response) => {
          this.showDatepicker = false;
          this.postQuotation();
        })
        .catch((error) => {
          this.handlePrereservationErrors(error);
        })
        .finally(() => {
          this.showLoader = false;
        });
  }

  public confirmAndOpenTrackingGuide(reservation): void {
    this.dialogRef.close();
    this.dialog.open(
      TrackingGuideComponent,
      {
        data: reservation,
        width: '90%',
        height: '95%',
        maxHeight: 800,
        maxWidth: 1020,
        disableClose: true,
      },
    );
  }

  public confirmCheckout(): void {
    this.showLoader = true;
    if (this.data.isEdition && this.showDatepicker) {
      this.updateReservationDate();
    } else {
      this.postShipping();
    }
  }

  public postShipping(): void {
    const reservation = this.reservationShipping;
    delete reservation.id;
    this.carriers.postShipping(this.reservationId, reservation)
        .then((response) => {
          this.confirmAndOpenTrackingGuide(response);
        })
        .catch((error) => {
          this.handlePrereservationErrors(error);
        })
        .finally(() => {
          this.showLoader = false;
        });
  }

  public postQuotation(): void {
    this.showLoader = true;
    const body = this.reservationShipping;
    delete body.id;
    this.carriers
      .postQuotation(this.reservationId, body)
      .then((response) => {
        const { quoting } = response;
        this.data.reservation = quoting;
        this.isDateUpdated = true;
      })
      .catch((error) => {
        this.handleQuotationErrors(error);
      })
      .finally(() => {
        this.showLoader = false;
      });
  }

  public handleQuotationErrors(error): void {
    const { status_code, type } = error.error;
    const data = {
      title: this.getQuotingTitleErrorMessage(status_code),
      message: this.getQuotingErrorMessage(status_code, type),
      buttonMessage: 'Intentar de nuevo',
      otherOption: 'Salir',
      image: 'sad.svg',
    };
    const dialogRef = this.dialog.open(DialogResponseComponent, { data });
    dialogRef
      .afterClosed().subscribe((result) => {
        if (!result) {
          this.dialog.closeAll();
        }
      });
  }

  /**
   * Returns a human readable result
   * title to be shown on modal
   */
  public getQuotingTitleErrorMessage(status: number): string {
    let title;
    switch (status) {
      case 400:
        title = 'Ocurrió un error al procesar la solicitud';
        break;
      case 403:
        title = 'Ocurrió un error intentando crear la reservación';
        break;
      case 410:
        title = 'El servicio no se encuentra disponible';
        break;
      default:
        title = 'Ocurrió un error intentando crear la reservación';
        break;
    }
    return title;
  }

  /**
   * Returns a human readable result
   * message to bw shown on modal
   */
  public getQuotingErrorMessage(status: number, type: string): string {
    let message;
    switch (status) {
      case 400:
        message = `Ocurrió un error al procesar la solicitud (${type})`;
        break;
      case 403:
        message = `La sesión caducó o los permisos del usuario son insuficientes (${type})`;
        break;
      case 410:
        message = `Intentamos crear tu reservación pero el servicio no se encuentra disponible,
      intenta crear tu reservación más tarde (${type})`;
        break;
      default:
        message = `Intenta crear una reservación de nuevo  (${type})`;
        break;
    }
    return message;
  }


  /**
   * Handles  error from prereservation API call
  */
  public handlePrereservationErrors(error: HttpErrorResponse): void {
    const { status_code, type } = error.error;
    const data = {
      title: this.getHumanTitleErrorMessage(status_code),
      message: this.getHumanErrorMessage(status_code, type),
      buttonMessage: 'Intentar de nuevo',
      otherOption: 'Salir',
      image: 'sad.svg',
    };
    const dialogRef = this.dialog.open(DialogResponseComponent, { data });
    dialogRef
      .afterClosed().subscribe((result) => {
        if (!result) {
          this.dialog.closeAll();
        }
      });
  }

  /**
   * Returns a human readable result
   * title to bw shown on modal
  */
  public getHumanTitleErrorMessage(status: number): string  {
    let title;
    switch (status) {
      case 400:
        title = 'Ocurrió un error al procesar la solicitud';
        break;
      case 403:
        title = 'Oops.. Error inesperado';
        break;
      case 410:
        title = 'Ocurrió un error al procesar la solicitud';
        break;
      default:
        title = 'Ocurrió un error intentando crear la reservación';
        break;
    }
    return title;
  }

  /**
   * Returns a human readable result
   * message to bw shown on modal
  */
  public getHumanErrorMessage(status: number, type: string): string {
    let message;
    switch (status) {
      case 400:
        message = `Ocurrió un error al procesar la solicitud (${type}`;
        break;
      case 403:
        message = `La sesión caducó o los permisos del usuario son insuficientes (${type})`;
        break;
      case 410:
        message = `La guía que has decidido crear no ha podido ser procesada, intenta de nuevo con otra reservación (${type})`;
        break;
      case 422:
        message = `La reservación solicitada contiene campos que no fueron llenados, intentalo de nuevo (${type})`;
        break;
      case 424:
        message = `Occurrió un fallo intentando conectar con la operadora de recolección,  inténtelo más tarde (${type})`;
        break;
      default:
        message = `Ocurrió un error intentando crear la reservación (${type})`;
        break;
    }
    return message;
  }

}
