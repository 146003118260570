import { MainDashboardComponent } from './navigation/main-dashboard/main-dashboard.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthService} from './core/auth.service';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'login',
    pathMatch: 'full',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'dashboard',
    component: MainDashboardComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'general'
      },
      {
        path: 'general',
        pathMatch: 'full',
        loadChildren: () => import('./modules/general/general.module').then(m => m.GeneralModule),
      },
      {
        path: 'history',
        pathMatch: 'full',
        loadChildren: () => import('./modules/history/history.module').then(m => m.HistoryModule),
      },
      {
        path: 'profile',
        pathMatch: 'full',
        loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'lockers',
        pathMatch: 'full',
        loadChildren: () => import('./modules/lockers/lockers.module').then(m => m.LockersModule),
      },
      {
        path: 'settings',
        pathMatch: 'full',
        loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
      },
      {
        path: 'clients',
        pathMatch: 'full',
        loadChildren: () => import('./modules/clients/clients.module').then(m => m.ClientsModule),
      },
    ],
    canActivateChild: [AuthService]
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }


