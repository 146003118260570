import { UserInterface } from 'app/util/interfaces.interface';
import { UsersService } from 'app/core/users.service';
import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-personal-card',
  templateUrl: './personal-card.component.html',
  styleUrls: ['./personal-card.component.scss']
})
export class PersonalCardComponent implements OnInit {
  user: UserInterface = { company: { name: '', id: '' } };
  constructor(
    private usersService: UsersService,
  ) { }

  ngOnInit() {
    this.getUserDetails();
  }

  public getUserDetails() {
    this.usersService.getCurrentUser()
      .then((res: { user: UserInterface }) => {
        this.user = res.user;
      });
  }

}
