import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {AsideElement} from '../../util/interfaces.interface';
import {AuthService} from '../../core/auth.service';

@Component({
  selector: 'app-aside-nav',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss']
})

export class AsideComponent implements OnInit {
  items: Array<AsideElement> = [];

  asideList: Array<AsideElement> = [
    {title: 'General', icon: 'assets/images/general-icon.svg', route: '/dashboard/general', permissions: []},
    {title: 'Reservar', icon: 'assets/images/lockers-icon.svg', route: '/dashboard/clients', permissions: ['reporter'] },
    {
      title: 'Perfil',
      icon: 'assets/images/profile.svg',
      route: '/dashboard/profile',
      permissions: ['reporter', 'admin', 'employee', 'seller'],
    },
    {
      title: 'Ajustes',
      icon: 'assets/images/settings.svg',
      route: '/dashboard/settings',
      permissions: ['reporter', 'admin', 'employee'],
      sections: [
        {
          title: 'Notificaciones',
          icon: 'assets/images/correo.svg',
          route: '/dashboard/settings',
          permissions: [],
        },
      ]
    },
  ];

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.filterSections();
  }

  filterSections() {
    this.asideList.forEach(element => {
      if (element.permissions !== undefined) {
        const permissionUnavailable = element.permissions.find((permission) => permission === this.authService.scope);
        if (permissionUnavailable === undefined) {
          this.items.push(element);
        }
      }
    });
  }

  logout() {
    this.authService.logout();
  }

}
