import {Component, Inject, OnInit} from '@angular/core';
import {BoxInterface, ReservationInterface} from '../../../util/interfaces.interface';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-complete-reservation',
  templateUrl: './complete-reservation.component.html',
  styleUrls: ['./complete-reservation.component.scss']
})
export class CompleteReservationComponent implements OnInit {

  date = '';
  box: BoxInterface = {
    width: 0,
    depth: 0,
    height: 0,
    size: ''
  };

  constructor(private dialogRef: MatDialogRef<CompleteReservationComponent>,
              @Inject(MAT_DIALOG_DATA) public data) {
  }

  ngOnInit() {
    switch (this.data.reservation.reservation.locker.size) {
      case 's':
        this.box.width = 38;
        this.box.depth = 56;
        this.box.height = 9;
        this.box.size = 'chico';
        break;
      case 'm':
        this.box.width = 38;
        this.box.depth = 56;
        this.box.height = 21;
        this.box.size = 'mediano';
        break;
      case 'l':
        this.box.width = 38;
        this.box.depth = 56;
        this.box.height = 34;
        this.box.size = 'grande';
        break;
    }
    this.date = this.data.reservation.reservation.created_at;
  }

  onClose(): void {
    this.dialogRef.close(true);
  }

}
