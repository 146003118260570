import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from '@sentry/browser';
import {environment} from '@env/environment';

Sentry.init({
  dsn: 'https://6db9dae0e7cb446c9fa51ae46673cc4f@o402489.ingest.sentry.io/5263722',
  integrations: [new Sentry.Integrations.TryCatch({
    XMLHttpRequest: false,
  })],
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() { }

  extractError(error) {
    if (error && error.ngOriginalError) {
      error = error.ngOriginalError;
    }

    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    if (error instanceof HttpErrorResponse) {

      if (error.error instanceof Error) {
        return error.error;
      }

      if (error.error instanceof ErrorEvent) {
        return error.error.message;
      }

      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      return error.message;
    }

    return null;
  }

  handleError(error) {
    const extractedError = this.extractError(error) || 'Handled unknown error';

    if (environment.production) {
      Sentry.captureException(extractedError);
    } else {
      console.log(error);
    }
  }
}
