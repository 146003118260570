import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HistoryRoutingModule } from './history-routing.module';
import { HistoryComponent } from './pages/history/history.component';
import { HistorySectionBarComponent } from './components/history-section-bar/history-section-bar.component';
import { HistoryTableComponent } from './components/history-table/history-table.component';

@NgModule({
  declarations: [HistoryComponent, HistorySectionBarComponent, HistoryTableComponent],
  imports: [
    CommonModule,
    HistoryRoutingModule
  ]
})
export class HistoryModule { }
