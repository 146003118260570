import { AuthSocket } from './core/auth.socket';
import { CoreModule } from './core/core.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HistoryModule } from './modules/history/history.module';
import { ProfileModule } from './modules/profile/profile.module';

import { MatDialogModule } from '@angular/material';
import { SharedModule } from './shared/shared.module';
import { GlobalUtil } from './util/global.util';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AccessTokenInterceptor } from './core/access-token.interceptor';
import { SocketIoModule } from 'ngx-socket-io';
import { SentryErrorHandler } from './core/sentry.service';
// import { FilterPipe } from './modules/general/pipes/filter.pipe';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SocketIoModule,
    AppRoutingModule,
    CoreModule,
    HistoryModule,
    ProfileModule,
    HttpClientModule,
    MatDialogModule,
    SharedModule,
    NgbModule,
  ],
  providers: [
    GlobalUtil,
    AuthSocket,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessTokenInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
