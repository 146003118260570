import { Observable } from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private httpClient: HttpClient) { }

  public getCurrentUser(): Promise<any> {
    const path = environment.BACKEND_API_MS_ENDPOINT_URL + 'oauth/user';
    return this.httpClient.get(path).toPromise();
  }

  public getCSVReport(): Observable<any> {
    const path = environment.BACKEND_API_MS_ENDPOINT_URL + `company/action_logs`;
    return this.httpClient.get(path);
  }
}
