import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '@env/environment';
import { ManagementInterface } from 'app/util/interfaces.interface';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  constructor(private httpClient: HttpClient) {
  }

  public sendEmails(reservationId, emails): Promise<any> {
    const path = environment.BACKEND_API_MS_ENDPOINT_URL + 'company/' + reservationId + '/mail';
    const body = {
      operator_email: undefined,
      administrator_email: undefined
    };

    if (emails.operator) {
      body.operator_email = emails.operator;
    }

    if (emails.admin) {
      body.administrator_email = emails.admin;
    }

    return this.httpClient.post(path, body).toPromise();
  }

  public editEmailOperator(emails): Promise<any> {
    const path = environment.BACKEND_API_MS_ENDPOINT_URL + 'company/notification_emails';
    return this.httpClient.put(path, emails).toPromise();
  }

  public getEmailOperators(): Promise<any> {
    const path = environment.BACKEND_API_MS_ENDPOINT_URL + 'company/notification_emails';
    return this.httpClient.get(path).toPromise();
  }
}
