import { AuthService } from './auth.service';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import {  HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import {from, Observable, throwError} from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {DialogResponseComponent} from '../shared/dialog-response/dialog-response.component';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {AuthSocket} from './auth.socket';

@Injectable()

export class  AccessTokenInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService,
                private router: Router,
                private dialog: MatDialog) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (request.url.includes('oauth/authorize')) {
        return next.handle(request);
      }
      return next.handle(this.addToken(request))
          .pipe(
            catchError(error => {
              if (error instanceof HttpErrorResponse && error.error.status_code === 401) {
                return this.handle401Error(request, next);
              } else {
                return throwError(error);
              }
          }),
      );
    }

    private addToken(request: HttpRequest<any>) {
        return request.clone({
          setHeaders: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${this.authService.accessTokenInfo}`,
          }
        });
    }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    return from(this.authService.refreshToken())
      .pipe(
        catchError((error) => {
          this.redirectToLogin();
          return next.handle(request);
        }),
        switchMap((token) => {
          return next.handle(this.addNextToken(request, token));
        }),
      );
  }

  private addNextToken(request: HttpRequest<any>, token: any) {
    localStorage.setItem('responseToken', JSON.stringify(token));
    AuthSocket.getInstance(this.authService).authenticate(token);
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${this.authService.accessTokenInfo}`
      }
    });
  }

  redirectToLogin() {
      this.router.navigateByUrl('login');
      this.dialog.closeAll();
      AuthSocket.getInstance(this.authService).disconnect();
      this.dialog.open(DialogResponseComponent, {
        data: {
          title: 'Tu sesión ha caducado',
          message: 'Vuelve a iniciar sesión para seguir administrando tu red',
          buttonMessage: 'De acuerdo',
        }
      });
      localStorage.clear();
    }
}




