import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-history-section-bar',
  templateUrl: './history-section-bar.component.html',
  styleUrls: ['./history-section-bar.component.scss']
})
export class HistorySectionBarComponent implements OnInit {
  public historyItems = [
    { title: 'Reservaciones' , query: '' , isActive: false },
    { title: 'Depósitos' , query: '', isActive: false },
    { title: 'Recolecciones' , query: '', isActive: false },
    { title: 'Cancelaciones' , query: '', isActive: false },
    { title: 'No depositadas' , query: '', isActive: false },
    { title: 'No recolectados' , query: '', isActive: true },
    { title: 'Todos' , query: '*', isActive: false }
  ];

  constructor() { }

  ngOnInit() {
  }

}
