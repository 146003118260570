import { ReservationsService } from './../../core/reservations.service';
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-create-reservation',
  templateUrl: './create-reservation.component.html',
  styleUrls: ['./create-reservation.component.scss']
})
export class CreateReservationComponent {

  selectedBox: string = null;
  selectedLocation: string = null;
  isLinear = false;
  isLoading = false;

  constructor(public reservationsService: ReservationsService) { }

}
