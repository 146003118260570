import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BoxInterface} from '../../../util/interfaces.interface';
import {GlobalUtil} from '../../../util/global.util';
import {MatStepper} from '@angular/material';

@Component({
  selector: 'app-select-box',
  templateUrl: './select-box.component.html',
  styleUrls: ['./select-box.component.scss']
})
export class SelectBoxComponent implements OnInit {

  selectedBox: string;
  boxes: Array<BoxInterface> = [
    {
      width: 38,
      height: 9,
      depth: 56,
      size: 'Chico',
      value: 's',
      image: 'box-chico.svg'
    },
    {
      width: 38,
      depth: 56,
      height: 21,
      size: 'Mediano',
      value: 'm',
      image: 'box-medium.svg'
    },
    {
      width: 38,
      depth: 56,
      height: 34,
      size: 'Grande',
      value: 'l',
      image: 'box-grande.svg'
    },
  ];

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onBoxSizeChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(public globalUtil: GlobalUtil,
              private stepper: MatStepper) { }

  ngOnInit() { }

  onContinue(): void {
    this.stepper.next();
  }

  public getSizeSelected() {

  }

  public select(box) {
    this.onBoxSizeChange.emit(box.value);
  }

}

