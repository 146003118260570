import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {ReservationsService} from '../../core/reservations.service';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-search-reservation',
  templateUrl: './search-reservation.component.html',
  styleUrls: ['./search-reservation.component.scss']
})
export class SearchReservationComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<SearchReservationComponent>,
              private reservationsService: ReservationsService) {
  }

  ngOnInit() { }

  onSearch(form: NgForm): void {
    const searchTerm = form.value.shipmentID;
    this.reservationsService.searchReservation(searchTerm)
    .then(result => {
      this.dialogRef.close(result);
    }).catch(() => {
      this.dialogRef.close({shipment_id: searchTerm});
    });
  }

}
