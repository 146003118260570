import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {ReservationsService} from '../../core/reservations.service';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-cancel-reservation',
  templateUrl: './cancel-reservation.component.html',
  styleUrls: ['./cancel-reservation.component.scss']
})
export class CancelReservationComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<CancelReservationComponent>,
              private reservationsService: ReservationsService) {
  }

  ngOnInit() {
  }

  onSearch(form: NgForm): void {
    this.reservationsService.searchReservation(form.value.shipmentID)
    .then(result => {
      this.dialogRef.close(result);
    }).catch(() => {
      this.dialogRef.close({shipment_id: form.value.shipmentID});
    });
  }
}
