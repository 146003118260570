import { EventHistoryInterface, LocationInterface, ReservationInterface } from './interfaces.interface';

export class GlobalUtil {
  getStatus(status: any): string {
    let result: string;
    switch (status) {
      case 'pending':
        result = 'pendiente';
        break;
      case 'delivered':
        result = 'depositado';
        break;
      case 'reopened':
        result = 'editado';
        break;
      case 'reopened2':
        result = 'editado';
        break;
      case 'cancelled':
        result = 'cancelado';
        break;
      case 'picked_up':
        result = 'recolectado';
        break;
      case 'reserved':
        result = 'reservado';
        break;
      default:
        result = status;
        break;
    }
    return result;
  }

  getClass(status: any): string {
    let result = 'text-aqua';
    switch (status) {
      case 'pending':
        result = 'text-orange';
        break;
      case 'delivered':
        result = 'text-aqua';
        break;
      case 'reopened':
        result = 'text-electric-blue';
        break;
      case 'cancelled':
        result = 'text-orange';
        break;
      case 'picked_up':
        result = 'text-electric-blue';
        break;
      case 'reserved':
        result = 'text-aqua';
        break;
      default:
        result = 'text-orange';
        break;
    }
    return result;
  }

  sortLocations(locations: Array<LocationInterface>, key?: string, order?: string): Array<LocationInterface> {
    switch (key) {
      case 'address':
        locations.sort((a, b) =>
          // tslint:disable-next-line:max-line-length
          a.address.street.replace(/([a|A][v|V][.]*)/, '').trim() > b.address.street.replace(/([a|A][v|V][.]*)/, '').trim() ? (order === 'desc' ? -1 : 1) : (order === 'desc' ? 1 : -1));
        break;
      case 'available':
        // tslint:disable-next-line: max-line-length
        locations.sort((a, b) => a.lockers_total_count - a.lockers_occupied_count > b.lockers_total_count - b.lockers_occupied_count ? (order === 'desc' ? -1 : 1) : (order === 'desc' ? 1 : -1));
        break;
      case 'occupation':
        // tslint:disable-next-line: max-line-length
        locations.sort((a, b) => a.lockers_occupied_count > b.lockers_occupied_count ? (order === 'desc' ? -1 : 1) : (order === 'desc' ? 1 : -1));
        break;
      default:
        locations.sort((a, b) =>
          // tslint:disable-next-line:max-line-length
          a.address.street.replace(/([a|A][v|V][.]*)/, '').trim() > b.address.street.replace(/([a|A][v|V][.]*)/, '').trim() ? (order === 'desc' ? -1 : 1) : (order === 'desc' ? 1 : -1));
        break;
    }
    return locations;
  }

  sortReservations(reservations: Array<ReservationInterface>, key?: string, order?: string): Array<ReservationInterface> {
    switch (key) {
      case 'date':
        reservations.sort((a, b) =>
          // tslint:disable-next-line:max-line-length
          new Date(this.getLastEvent(a).date) < new Date(this.getLastEvent(b).date) ? (order === 'desc' ? -1 : 1) : (order === 'desc' ? 1 : -1));
        break;
      case 'location':
        reservations.sort((a, b) =>
          // tslint:disable-next-line:max-line-length
          a.location.internal_name.replace(/([a|A][v|V][.]*)/, '').trim() > b.location.internal_name.replace(/([a|A][v|V][.]*)/, '').trim() ? (order === 'desc' ? -1 : 1) : (order === 'desc' ? 1 : -1));
        break;
      default:
        reservations.sort((a, b) =>
          // tslint:disable-next-line:max-line-length
          new Date(this.getLastEvent(a).date) < new Date(this.getLastEvent(b).date) ? (order === 'desc' ? -1 : 1) : (order === 'desc' ? 1 : -1));
        break;
    }
    return reservations;
  }

  getLastEvent(reservation: ReservationInterface): EventHistoryInterface {
    const events = reservation.event_history.filter(event => !['undelivered', 'unpicked'].includes(event.status));
    const lastEvent = events[events.length - 1];
    return lastEvent;
  }

  getLockerSize(size: string): string {
    let res = '';
    switch (size) {
      case 's':
        res = 'Chico';
        break;
      case 'm':
        res = 'Mediano';
        break;
      case 'l':
        res = 'Grande';
        break;
      default:
        res = 'N/A';
        break;
    }
    return res;
  }

  getLockerMeasures(height: number, depth: number, width: number): string {
    return height.toString() + 'CM x ' + depth.toString() + 'CM x ' + width.toString() + 'CM';
  }

  getLocationAddress(location: LocationInterface, completeAddress?: boolean): string {
    if (completeAddress) {
      return location.address.street + ' ' + location.address.number + ', ' + location.address.town + ', ' +
        location.address.zip_code + ', ' + location.address.city;
    }
    return location.address.street + ' ' + location.address.number + ', ' + location.address.city;
  }

  compareDate(a: Date, b: Date) {
    const sameDate = a.getFullYear() === b.getFullYear()
      && a.getMonth() === b.getMonth()
      && a.getDate() === b.getDate();

    if (sameDate) {
      return 0;
    }

    return a < b ? -1 : 1;
  }
}
