import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss']
})
export class InfoCardComponent implements OnInit, OnChanges {

  @Input() title: string;
  @Input() metric: string;
  @Input() metricPercent: string;
  @Input() hasDetails: boolean;
  @Input() percent: string;
  @Input() icon: string;
  percentClass = '';

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.percentClass = this.percent.charAt(0) !== '-' ? 'percent--up' : 'percent--down';
  }

}
