import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthSocket } from 'app/core/auth.socket';
import { AuthService } from 'app/core/auth.service';

@Component({
  selector: 'app-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.scss']
})

export class MainDashboardComponent implements OnInit, OnDestroy {
  socket: any;

  constructor(auth: AuthService) {
    this.socket = AuthSocket.getInstance(auth);
  }

  ngOnInit() {
    const _hsq = window['_hsq'] = window['_hsq'] || [];
    _hsq.push(['setPath', '/dashboard/']);
    _hsq.push(['trackPageView']);
  }

  ngOnDestroy() {
    this.socket.client.disconnect();
  }

}
