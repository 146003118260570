import { DialogResponseComponent } from './../../../../shared/dialog-response/dialog-response.component';
import { MatDialog } from '@angular/material';
import { UsersService } from 'app/core/users.service';
import { Component } from '@angular/core';
import { ngxCsv } from 'ngx-csv/ngx-csv';

@Component({
  selector: 'app-reports-card',
  templateUrl: './reports-card.component.html',
  styleUrls: ['./reports-card.component.scss'],
})
export class ReportsCardComponent {
  private months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  public showLoading = false;

  constructor(
    private dialog: MatDialog,
    private usersService: UsersService,
    ) {}

  public downloadReport(): void {
    this.showLoading = true;
    this.usersService.getCSVReport().subscribe(({ action_logs }) => {
      const name = this.getTimestampName();
      const csv = new ngxCsv(action_logs, name);
    }, () => {
      this.showdialogResponse();
    }, () => {
      this.showLoading = false;
    });
  }

  public getTimestampName(): string {
    const today = new Date();
    const mm = today.getMonth() + 1;
    const dd = today.getDate();
    const yy = today.getFullYear();
    return `Actividad-en-sitio-${dd}-de-${this.months[mm - 1]}-${yy}`;
  }

  public showdialogResponse() {
    this.dialog.open(DialogResponseComponent,
      {
        data: {
          title: 'Ocurrió un error intentando general el reporte',
          message: 'Intenta descargarlo más tarde',
          image: 'sad.svg',
          buttonMessage: 'Entendido',
        }
      });
  }
}
