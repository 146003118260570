import { Component, OnInit, Input } from '@angular/core';
import * as jsPDF from 'jspdf';
import { DomSanitizer } from '@angular/platform-browser';
import { CarriersService } from './../../../core/carriers.service';

@Component({
  selector: 'app-tracking-guide-ticket',
  templateUrl: './tracking-guide-ticket.component.html',
  styleUrls: ['./tracking-guide-ticket.component.scss']
})
export class TrackingGuideTicketComponent implements OnInit {
  @Input() reservation: any;

  constructor(
    private sanitizer: DomSanitizer,
    private carriers: CarriersService,
  ) { }

  public showDownloadedPdf = false;
  public isLoading = false;
  public trackingGuideEncode = '';

  public sanitizeUrl(uri: string) {
    return `url(${this.sanitizer.bypassSecurityTrustUrl(uri)})`;
  }

  public downloadTrackingGuide() {
    this.isLoading = true;
    return this.carriers.getShippingLabel(this.reservation.reservation.id)
      .then((data: any) => {
        const pdf = new jsPDF();

        this.trackingGuideEncode = 'data:image/jpeg;base64,' + data.jpeg;
        pdf.addImage(this.trackingGuideEncode, 'JPEG', 0, 0);
        return pdf.save('guia.pdf', { returnPrmise: true });
      })
      .then(() => {
         this.isLoading = false;
         this.showDownloadedPdf = true;
      });
  }

  ngOnInit() {
    if (this.reservation !== undefined) {
      return this.downloadTrackingGuide();
    }
  }
}
